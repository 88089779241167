import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { SiteMetadata } from "../../types";

export const GlobalNav: React.FC = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          homepageTitle
          homepageURL
        }
      }
    }
  `) as { site: { siteMetadata: SiteMetadata } };
  return (
    <nav className="navbar">
      <Link to="/">{title}</Link>
    </nav>
  );
};
