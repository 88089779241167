import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

import { SiteMetadata, KleinboyArticle, KleinboyTag } from "../../types";
import { pathJoin, dirname } from "../../utils/url";
import { siteMetadata } from "../../../gatsby-config";

const makeJSONLDPublisher = ({ title, logoURL, logoSize }: SiteMetadata) => {
  return {
    "@type": "Organization",
    name: title,
    logo: {
      "@type": "ImageObject",
      url: logoURL,
      width: logoSize.width,
      height: logoSize.height,
    },
  };
};

const makeJSONLDAuthor = ({ authorName, authorImageURL, authorImageSize, homepageURL }: SiteMetadata) => {
  return {
    "@type": "Person",
    name: authorName,
    image: {
      "@type": "ImageObject",
      url: authorImageURL,
      width: authorImageSize.width,
      height: authorImageSize.height,
    },
    url: homepageURL,
    sameAs: [] as string[],
  };
};

const makeJSONLD = (siteMetadata: SiteMetadata, article: KleinboyArticle, tags?: KleinboyTag[]) => {
  const tag =
    tags != null
      ? {
          keywords: tags.map((t) => t.title).join(", "),
        }
      : {};
  const obj = {
    "@context": "https://schema.org",
    "@type": "Article",
    publisher: makeJSONLDPublisher(siteMetadata),
    author: makeJSONLDAuthor(siteMetadata),
    headline: article.title,
    url: pathJoin(siteMetadata.url, article.path),
    datePublished: article.publishedTime,
    dateModified: article.modifiedTime,
    description: article.description,
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": siteMetadata.url,
    },
    ...tag,
  };
  return JSON.stringify(obj);
};

type Props = {
  title: string;
  description?: string;
  lang?: string;
  meta?: { name: string; content: string }[];
  article?: KleinboyArticle;
  tags?: KleinboyTag[];
};

export const SEO: React.FC<Props> = ({ description, lang, meta, title, article, tags }: Props) => {
  const {
    site: {
      siteMetadata: { title: siteTitle, description: siteDescription, authorName, authorTwitter, defaultOgImageURL },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            authorName
            authorTwitter
            authorImageURL
            authorImageSize {
              width
              height
            }
            defaultOgImageURL
            logoURL
            logoSize {
              width
              height
            }
            homepageURL
          }
        }
      }
    `,
  ) as { site: { siteMetadata: SiteMetadata } };

  const metaDescription = description || siteDescription;

  const fullTitle = title != null ? `${title} - ${siteTitle}` : title;

  let twitterCard = "summary";

  const image = (() => {
    // eslint-disable-next-line no-constant-condition
    if (article != null) {
      if (article.images != null && article.images.length >= 1) {
        twitterCard = "summary_large_image";
        return pathJoin(siteMetadata.url, dirname(article.path), article.images[0]);
      }
    }
    return undefined;
  })();

  const articleMeta = [];
  if (article != null) {
    let url = pathJoin(siteMetadata.url, article.path);
    if (!url.endsWith("/")) {
      url = url + "/";
    }
    articleMeta.push({
      property: "og:url",
      content: url,
    });
    if (article.publishedTime != null) {
      articleMeta.push({
        property: "article:published_time",
        content: article.publishedTime,
      });
    }
    if (article.modifiedTime != null) {
      articleMeta.push({
        property: "article:modified_time",
        content: article.modifiedTime,
      });
    }
    if (tags != null) {
      for (const tag of tags) {
        articleMeta.push({
          property: "article:tag",
          content: tag.title,
        });
      }
    }
    articleMeta.push({
      name: "twitter:url",
      content: url,
    });
  }

  let finalMeta = [
    {
      name: "description",
      content: metaDescription,
    },
    {
      property: "og:site_name",
      content: siteTitle,
    },
    {
      property: "og:type",
      content: article ? "article" : "website",
    },
    {
      property: "og:title",
      content: title,
    },
    {
      property: "og:description",
      content: metaDescription,
    },
    {
      property: "og:image",
      content: image || defaultOgImageURL,
    },
    {
      name: "twitter:card",
      content: twitterCard,
    },
    {
      name: "twitter:site",
      content: authorTwitter,
    },
    {
      name: "twitter:creator",
      content: authorTwitter,
    },
    {
      name: "twitter:title",
      content: fullTitle,
    },
    {
      name: "twitter:description",
      content: metaDescription,
    },
    ...articleMeta,
  ];
  if (meta != null) {
    finalMeta = finalMeta.concat(meta);
  }
  if (image != null) {
    finalMeta.push({
      name: "twitter:image",
      content: image,
    });
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: lang ?? "ja-JP",
        prefix: "og: http://ogp.me/ns#",
      }}
      title={title}
      titleTemplate={`%s - ${siteTitle}`}
      meta={finalMeta}
      script={
        article != null
          ? [{ type: "application/ld+json", innerHTML: JSON.stringify(makeJSONLD(siteMetadata, article, tags)) }]
          : undefined
      }
    />
  );
};
