const activeEnv = process.env.ACTIVE_ENV || process.env.NODE_ENV || "development";
if (typeof window === "undefined") {
  require("dotenv").config({
    path: `.env.${activeEnv}`,
  });
  if (activeEnv === "production" && process.env.GOOGLE_ANALYTICS_TRACKING_ID == null) {
    throw new Error("you must specify GOOGLE_ANALYTICS_TRACKING_ID");
  }
}

const pluginGoogleAnalytics =
  process.env.GOOGLE_ANALYTICS_TRACKING_ID != null
    ? [
        {
          resolve: "gatsby-plugin-google-analytics",
          options: {
            trackingId: process.env.GOOGLE_ANALYTICS_TRACKING_ID,
            head: true,
          },
        },
      ]
    : [];

module.exports = {
  siteMetadata: {
    title: "Möbius Flyer",
    description: "適当なことと不適当なことを書く。",
    url: "https://blog.alprosys.com/",
    logoURL: "https://blog.alprosys.com/assets/mobius_60.png",
    logoSize: { width: 60, height: 60 },
    defaultOgImageURL: "https://blog.alprosys.com/assets/mobius_og.png",
    authorName: "taskie",
    authorURL: "https://twitter.com/t_skie",
    authorTwitter: "@t_skie",
    authorImageURL: "//www.gravatar.com/avatar/5d907c1a9204f1a285d1934bba4fa1d6?s=250&d=mm&r=x",
    authorImageSize: { width: 250, height: 250 },
    homepageTitle: "ALPROSYS",
    homepageURL: "https://www.alprosys.com/",
  },
  plugins: [
    ...pluginGoogleAnalytics,
    "gatsby-plugin-react-helmet",
    {
      resolve: "gatsby-source-kleinboy",
      options: {
        name: "contents",
        path: `${__dirname}/contents`,
        deniedStatuses: activeEnv !== "development" ? ["disabled", "draft"] : ["disabled"],
      },
    },
    {
      resolve: "gatsby-source-filesystem",
      options: {
        name: "assets",
        path: `${__dirname}/static/assets`,
      },
    },
    "gatsby-plugin-catch-links",
    "gatsby-transformer-sharp",
    "gatsby-plugin-sharp",
    {
      resolve: "gatsby-transformer-remark",
      options: {
        commonmark: true,
        footnotes: true,
        pedantic: false,
        gfm: true,
        plugins: [
          {
            resolve: "gatsby-remark-images",
            options: {
              maxWidth: 900,
              linkImagesToOriginal: true,
            },
          },
          {
            resolve: "gatsby-remark-prismjs",
          },
        ],
      },
    },
    "gatsby-plugin-force-trailing-slashes",
    {
      resolve: "gatsby-plugin-manifest",
      options: {
        name: "Möbius Flyer",
        short_name: "MöF",
        start_url: "/",
        background_color: "#ffffff",
        theme_color: "#c7243a",
        display: "minimal-ui",
        icon: "static/assets/mobius_600.png",
      },
    },
    "gatsby-plugin-twitter",
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // "gatsby-plugin-offline",
  ],
};
