import React from "react";

import { GlobalNav } from "./GlobalNav";
import { GlobalFooterNav } from "./GlobalFooterNav";
import { TopHeaderNav } from "./TopHeaderNav";

import "./layout.css";
import { Helmet } from "react-helmet";

type Props = { top?: boolean };

export const Layout: React.FC<Props> = ({ top, children }) => {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="//www.alprosys.com/webfonts/mplus-2p-latin.css" />
      </Helmet>
      {top ? <TopHeaderNav /> : <GlobalNav />}
      <div className="container">
        <div className="content">
          <main>{children}</main>
        </div>
      </div>
      <GlobalFooterNav />
    </>
  );
};
