export const pathJoin = (...ss: string[]): string => {
  ss = ss.filter((s) => s != null);
  if (ss.length === 0) {
    return "";
  }
  let result = ss[0];
  for (let i = 1; i < ss.length; ++i) {
    const s = ss[i];
    const resultLastIsSlash = result[result.length - 1] === "/";
    const sFirstIsSlash = s[0] === "/";
    if (resultLastIsSlash && sFirstIsSlash) {
      result += s.slice(1);
    } else if (resultLastIsSlash || sFirstIsSlash) {
      result += s;
    } else {
      result += "/" + s;
    }
  }
  return result;
};

export const dirname = (s: string): string => {
  const pos = s.lastIndexOf("/");
  if (pos === -1) {
    return "";
  }
  return s.slice(0, pos);
};
