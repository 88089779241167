import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { SiteMetadata } from "../../types";

export const TopHeaderNav: React.FC = () => {
  const {
    site: {
      siteMetadata: { title, description },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `) as { site: { siteMetadata: SiteMetadata } };
  return (
    <header className="top-title">
      <h1>{title}</h1>
      <p>{description}</p>
    </header>
  );
};
