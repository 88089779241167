import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { SiteMetadata } from "../../types";
import { OutboundLink } from "gatsby-plugin-google-analytics";

export const GlobalFooterNav: React.FC = () => {
  const {
    site: {
      siteMetadata: { title, authorName, authorURL, homepageTitle, homepageURL },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          authorName
          authorURL
          homepageTitle
          homepageURL
        }
      }
    }
  `) as { site: { siteMetadata: SiteMetadata } };
  const currentYear = new Date().getFullYear();
  return (
    <nav className="footer-nav">
      <Link to="/">{title}</Link> by <OutboundLink href={homepageURL}>{homepageTitle}</OutboundLink> / &copy; 2016-
      {currentYear} <OutboundLink href={authorURL}>{authorName}</OutboundLink>
    </nav>
  );
};
